import styled, { css } from "styled-components";

import Grid from "@anwb/poncho/components/grid";
import { Image } from "@anwb/poncho/components/image";
import Panel from "@anwb/poncho/components/panel";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { Theme } from "@anwb/poncho/design-tokens/theme";

import {
  BodyL,
  BodyS,
  HeadingCompressedBoldM,
  HeadingCompressedBoldS,
  HeadingNarrowBoldCaps,
} from "@topgun/shared-front-end/src/typography";

type DefaultThemeProps = {
  theme: Theme;
};

const chapterOverviewWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  max-width: 80rem;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    margin: 0;
    padding: 1rem;
  }
`;

export const ChapterOverviewWrapper = styled.div`
  ${chapterOverviewWrapperStyles}
`;

const chapterOverviewGridStyles = ({ theme }: DefaultThemeProps) => css`
  @media (max-width: ${pxToRem(theme.viewportBreakpoint.lg)}) and (min-width: ${pxToRem(
      theme.viewportBreakpoint.sm,
    )}) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    > .PONCHO-grid__item {
      :nth-of-type(1) {
        grid-column: span 6;
      }
      :nth-of-type(2) {
        grid-column: span 10;
      }
    }
  }
`;

export const ChapterOverviewGrid = styled(Grid)`
  ${chapterOverviewGridStyles}
`;

const chapterOverviewProceedWithTitleStyles = ({ theme }: DefaultThemeProps) => css`
  margin-top: 0.333333rem;
  margin-bottom: 0.5rem;
  @media (max-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    margin-bottom: 0;
  }
`;

const chapterOverviewProceedWithStyles = ({ theme }: DefaultThemeProps) => css`
  @media (max-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    margin-bottom: 0;
  }
`;

export const ChapterOverviewProceedWithWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChapterOverviewProceedWithTitle = styled(HeadingNarrowBoldCaps)`
  ${chapterOverviewProceedWithTitleStyles}
`;

export const ChapterOverviewProceedWith = styled(BodyL)`
  ${chapterOverviewProceedWithStyles}
`;

const chapterOverviewCtaWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    margin-top: 1.5rem;
  }
`;

export const ChapterOverviewCtaWrapper = styled.div`
  ${chapterOverviewCtaWrapperStyles}
`;

export const ChapterOverviewCtaLabel = styled(HeadingCompressedBoldS)`
  max-width: initial;
`;

export const ChapterOverviewCtaArrow = styled(Image)`
  width: 4rem;
  height: 2rem;
  margin-left: 1rem;
`;

const horizontalDividerStyles = ({ theme }: DefaultThemeProps) => css`
  display: block;
  height: 2px;
  padding: 0;
  margin: -${pxToRem(theme.spacing["300"])} 0 ${pxToRem(theme.spacing["300"])};

  background: #ffffff;
  opacity: 0.2;
  border: 1px solid #ffffff;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    margin-top: ${pxToRem(theme.spacing["600"])};
  }
`;

export const HorizontalDivider = styled.hr`
  ${horizontalDividerStyles}
`;

export const ChapterCardGrid = styled(Grid)``;

export const ChapterPanel = styled(Panel)`
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 0.75rem;
  cursor: pointer;
  width: 100%; // calc(100% - 2.6rem);

  :hover {
    background: rgba(255, 255, 255, 0.15);
    filter: brightness(110%);
  }
`;

const chapterPanelContentStyles = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  flex-direction: row;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    flex-direction: column;
  }
`;

export const ChapterPanelContent = styled(Panel.Content)`
  ${chapterPanelContentStyles}
`;

const chapterCardWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  height: 200px;
  width: 350px;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    width: 100%;
    height: auto;
  }
`;

export const ChapterCardWrapper = styled.div`
  ${chapterCardWrapperStyles}
`;

const chapterDetailsWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  margin-left: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    margin-left: 0;
    margin-top: 2.5rem;
  }
`;

export const ChapterDetailsWrapper = styled.div`
  ${chapterDetailsWrapperStyles}
`;

export const ChapterTitle = styled(HeadingCompressedBoldM)`
  margin-bottom: 0;
`;

const chapterProgressDetailsStyles = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  align-items: center;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    margin-top: 2rem;
  }
`;

export const ChapterProgressDetails = styled.div`
  ${chapterProgressDetailsStyles}
`;

export const ChapterProgressPartsAmount = styled(BodyS)`
  margin: 0 1.5rem 0 0;
`;

export const ChapterProgressStatus = styled(BodyS)`
  margin: 0 0 0 0.5rem;
`;

export const ChapterSummary = styled(BodyL)`
  margin: 0.5rem 0 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
