import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

type PlayerWrapperProps = {
  $wide?: boolean;
} & DefaultThemeProps;

const styleRefreshStyles = ({ theme, $wide = false }: PlayerWrapperProps) => {
  const wideVideoPlayerStyles = $wide
    ? css`
        @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
          width: 100%;
          margin-inline: 2rem;
        }

        @media (min-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
          width: 65%;
        }
      `
    : "";

  return css`
    display: block;
    margin-inline: auto;
    position: relative;
    transition: width 0.2s;
    max-width: 100%;
    width: 100%;
    overflow-y: hidden;

    @media (min-width: 120rem) {
      max-width: 96.5rem;
    }

    @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
      width: calc(100vw - 5rem);
      overflow-y: auto;
      max-height: calc(100dvh - 13rem);
    }

    @media (min-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
      max-height: calc(100dvh - 13rem);
    }

    @media screen and (orientation: landscape) and (max-width: ${pxToRem(
        theme.viewportBreakpoint.md,
      )}) {
      max-height: calc(100dvh - 10rem);
    }

    ${wideVideoPlayerStyles}
  `;
};

export const StyleRefreshWrapper = styled.div<PlayerWrapperProps>`
  ${styleRefreshStyles};
`;

const videoWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  max-width: calc(100% - 24rem);
  max-height: calc(var(--unit-100vh) - 12rem - 1.5rem);

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    max-width: 100%;
  }

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    width: 100%;
    flex-grow: 1;
    padding: 0 1.5rem;
    border-radius: 0;
  }
`;

export const VideoWrapper = styled(StyleRefreshWrapper)`
  ${videoWrapperStyles};
`;
