import { User } from "@hooks/useUser";
import { QueryKey } from "@tanstack/react-query";

export const getConfigurationsQueryKey = (
  types: string[],
  belongsTo: string | undefined,
  accessToken: string | undefined,
): QueryKey => ["dynamic-activity-configurations", types, accessToken, belongsTo];

export const getConfigurationQueryKey = (
  configurationId: string,
  accessToken: string | undefined,
): QueryKey => ["dynamic-activity-configuration", configurationId, accessToken];

export const getDynamicActivityQueryKey = (
  dynamicActivityId: string,
  accessToken: string | undefined,
): QueryKey => ["dynamic-activity", dynamicActivityId, accessToken];

export const getProgressQueryKey = (
  userId: string | undefined,
  accessToken: string | undefined,
): QueryKey => ["progress", userId, accessToken];

export const getContentQueryKey = (user: User | null): QueryKey => [
  "content",
  user?.id,
  user?.accessToken,
];

export const getLearningObjectQueryKey = (
  learningObjectId: string | undefined,
  accessToken: string | undefined,
): QueryKey => ["learning-object", learningObjectId, accessToken];
