import { VIEWPORT_BREAKPOINT_XS } from "@components/HeaderMenu/styles";
import { createGlobalStyle, css } from "styled-components";

import { ModalContainer } from "@anwb/poncho/components/modal";
import { PopoverContentContainer, PopoverWrapper } from "@anwb/poncho/components/popover";
import { BASE_FONT_SIZE, pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import { customVariables } from "@topgun/shared-front-end/src/theme/custom-variables";

export * from "./application.styled";

const legacyCoreUIStyle = css`
  /**
   * Left-overs of old core-ui global styling.
   * Copied from { legacyGlobalTypographyStyles } from "@anwb/theme";
   */

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    font-size: ${BASE_FONT_SIZE}px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
    line-height: 1.6;
  }
`;

const normalization = css`
  body {
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${legacyCoreUIStyle}
  ${normalization}

  :root {
    --unit-100vh: 100vh;
  }
  @supports (height: 100dvh) {
    :root {
      --unit-100vh: 100dvh;
    }
  }

  body {
		background-color: ${customVariables.colors.primaryBackground};
		color: #ffffff;
  }

  #topgun-end-user-front-end {
		height: 100%;
  }

  ${PopoverWrapper} {
    background-color: inherit;
  }

  ${PopoverContentContainer} {
    border-radius: 0 0 1rem 1rem;
    background-color: #001b3c;
    overflow-y: auto;
    margin-top: -1px;

    @media (max-width: ${pxToRem(VIEWPORT_BREAKPOINT_XS)}) {
      right: 1rem;
    }

    && footer {
      background-color: #001b3c;
    }
  }

  ${ModalContainer} {
    backdrop-filter: blur(5px);

    @media (max-width: ${({ theme }: DefaultThemeProps) => pxToRem(theme.viewportBreakpoint.md)}) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
