import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";

import { ErrorDetails } from "@components/Activity/styles/activity.styled";
import { NotificationWrapper } from "@components/Notification/styles/Notification.styled";
import { useUser } from "@hooks/useUser";

import NotificationDefault, { NotificationProps } from "@anwb/poncho/components/notification";

export type StyledNotificationProps = {
  $alignNotification?: boolean;
} & NotificationProps;

const Notification: FunctionComponent<StyledNotificationProps> = (props) => {
  const { $alignNotification, ...rest } = props;
  const user = useUser();
  const location = useLocation();
  const time = new Date().toISOString();

  return (
    <NotificationWrapper $alignNotification={$alignNotification}>
      <NotificationDefault {...rest}>
        <ErrorDetails>
          USER_ID: {user?.id} <br /> PATH: {location.pathname} <br /> TIME: {time}
        </ErrorDetails>
      </NotificationDefault>
    </NotificationWrapper>
  );
};

export default Notification;
