import { useClient } from "@components/ClientProvider";
import { getLearningObjectQueryKey } from "@hooks/utils";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "oidc-react";

export default (learningObjectId: string) => {
  const { userData } = useAuth();
  const { dataClient } = useClient();

  const { access_token: accessToken } = userData || {};

  return useQuery({
    queryKey: getLearningObjectQueryKey(learningObjectId, accessToken),
    enabled: !!accessToken,
    queryFn: async ({ signal }) => {
      if (!accessToken) {
        throw new Error("Unauthenticated");
      }
      return dataClient.getLearningObject(learningObjectId, accessToken, signal);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
